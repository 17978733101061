import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';
import { ITallyContainerService } from '@erp/logistic';
import { IPlanningItem } from '@erp/planning';
import {
  BaseHttpService,
  ICollectionResponse,
  IPatchRequest,
  IProcessingOrderReportProductionResponse,
  IStockMaterialQuantityReference,
  removeEmptyAttributes
} from '@erp/shared';
import {
  IAncillaryGagList,
  IAncillaryItemResponse,
  IAncillarySawCutList,
  IBuckOn,
  IEntryOperationInventoryItem,
  IEntryOperationInventoryItemResponse,
  IEntryOperationReport,
  IProcessOrderStatus,
  IReclassifyNcpDetailDetails,
  IReclassifyNcpItem,
  IRoundThread,
  IVolumetricGrowth,
  IVolumetricGrowthItemList,
  IVolumetricGrowthMeasurements,
  IVolumetricGrowthTolerance
} from '@erp/tablet/production';
import {
  IAvailableMaterialPerDocument,
  IInventoryDetailsInventoryList
} from '@erp/tablet/production/modules/processing-order/modules/inventory-details/interfaces';
import { ERPInventoryDetailsSignalRService } from '@erp/tablet/production/modules/processing-order/modules/inventory-details/services';

import { PoAttachmentCategory } from '../enums';
import {
  IOperationDetail,
  IOperationReport,
  IProcessOrderAttachment,
  IProcessingOrderBatchInfo,
  IProcessingOrderLotNumber,
  IProcessingOrderPipeMeasurements,
  IProcessingOrderProgress,
  IProcessingOrderRemainingAllocatedMaterial,
  IProcessingOrderReportProduction,
  IProcessingOrderTallyItem,
  IProductionProcessingOrder,
  IProductionProcessingOrderList,
  IProductionProcessingOrderListTablet,
  IProductionProcessingOrderResponse,
  IProductionProcessingOrderRevesionControl,
  OperationDetails
} from '../interfaces';
import { IProcessingOrderConsumedMaterial, IProcessingOrderSelectedMaterial } from '../modules';

import { ProcessingOrderFactory } from './processing-order.factory';

@Injectable({
  providedIn: 'root'
})
export class ERPProductionProcessingOrderService
  extends BaseHttpService
  implements ITallyContainerService<IProcessingOrderTallyItem>
{
  constructor(
    readonly httpClient: HttpClient,
    readonly factory: ProcessingOrderFactory,
    readonly signalRService: ERPInventoryDetailsSignalRService
  ) {
    super('processingOrders');
  }

  getProcessingOrders(params: object) {
    return this.get<ICollectionResponse<IProductionProcessingOrderList>>(params);
  }

  getProcessingOrdersNames(params: object) {
    return this.get<ICollectionResponse<IProductionProcessingOrderList>>(params).pipe(
      map(({ data }) => data.map(el => el.documentNumber))
    );
  }

  getRemainingAllocatedMaterials(poId: number) {
    return this.get<IProcessingOrderRemainingAllocatedMaterial[]>({}, `${poId}/remainingAllocatedMaterials `);
  }

  createProcessingOrder(value: IProductionProcessingOrder) {
    const request = this.factory.toRequest(value);

    return this.post<IProductionProcessingOrderResponse>(request);
  }

  createProcessingOrderFromWizard(value: IProductionProcessingOrder) {
    const request = this.factory.toRequest(value);

    return this.post<IProductionProcessingOrderResponse>(request, {}, 'salesDerived');
  }

  updateProcessingOrder(value: IProductionProcessingOrder) {
    const request = this.factory.toRequest(value);

    return this.put<IProductionProcessingOrderResponse>(request, {}, request.id);
  }

  getProcessingOrderPreview() {
    return this.get<IProductionProcessingOrderResponse>({}, 'preview').pipe(
      map(response => this.factory.fromResponse(response))
    );
  }

  getProcessingOrder(id: string | number, skipLoader = false): Observable<IProductionProcessingOrder> {
    const options = skipLoader ? { headers: { 'skip-loader': 'true' } } : {};

    return this.get<IProductionProcessingOrderResponse>({}, id, options).pipe(
      map(response => this.factory.fromResponse(response))
    );
  }

  getSelectedMaterials(id: number): Observable<IProcessingOrderSelectedMaterial[]> {
    return this.get<IProcessingOrderSelectedMaterial[]>({}, `${id}/consumption/short`);
  }

  getConsumptionLogMaterials(id: number): Observable<IProcessingOrderConsumedMaterial[]> {
    return this.get<IProcessingOrderConsumedMaterial[]>({}, `${id}/consumption`);
  }

  getConsumptionByProcessServiceId(
    id: number,
    serviceId: number,
    params: object = {}
  ): Observable<IProcessingOrderConsumedMaterial[]> {
    return this.get<IProcessingOrderConsumedMaterial[]>(params, `${id}/service/${serviceId}/consumption`);
  }

  getConsumptionById(id: number): Observable<IProcessingOrderConsumedMaterial> {
    const defaultServiceId = 0;

    return this.get<IProcessingOrderConsumedMaterial>({}, `${id}/consumption/${id}`);
  }

  createReportConsumption(
    id: number,
    value: IProcessingOrderConsumedMaterial[],
    params?: object
  ): Observable<IProcessingOrderConsumedMaterial[]> {
    return this.post<IProcessingOrderConsumedMaterial[]>(value, params, `${id}/consumption`);
  }

  getRevisionControls(params: object, id: string) {
    return this.get<ICollectionResponse<IProductionProcessingOrderRevesionControl>>(params, `${id}/history`);
  }

  // TODO: typings need to be updated
  createReportProduction(
    id: number,
    value: { [key: string]: unknown }[],
    params?: object
  ): Observable<{ [key: string]: unknown }[]> {
    return this.post<{ [key: string]: unknown }[]>(value, params, `${id}/outcomes`);
  }

  createFinishedGoods(
    id: number,
    value: {
      consumptions: { [key: string]: unknown }[];
      productions: { [key: string]: unknown }[];
    },
    params?: object
  ) {
    return this.post<{
      consumptions: { [key: string]: unknown }[];
      productions: { [key: string]: unknown }[];
    }>(value, params, `${id}/consumptionAndOutcome/finishedGoods`);
  }
  reportOperationDetails(proId: number, serviceId: number, operationDetails: OperationDetails) {
    return this.post<OperationDetails>(
      operationDetails,
      {},
      `${proId}/consumptionAndOutcome/processingService/${serviceId}/finishedGoods`
    );
  }

  getOperationDetails(proId: number, serviceId: number) {
    return this.get<OperationDetails>(
      {},
      `${proId}/consumptionAndOutcome/processingService/${serviceId}/finishedGoods`
    );
  }

  getProductionLogMaterials(params: object, id: number): Observable<IProcessingOrderReportProductionResponse[]> {
    return this.get<IProcessingOrderReportProductionResponse[]>(params, `${id}/outcomes`);
  }

  getProductionProcessServiceMaterials(
    id: number,
    processServiceId: number,
    params: object = {}
  ): Observable<ICollectionResponse<IProcessingOrderReportProduction>> {
    return this.get<ICollectionResponse<IProcessingOrderReportProduction>>(
      params,
      `${id}/service/${processServiceId}/outcomes`
    );
  }

  getProcessingOrdersScheduled(params: object): Observable<ICollectionResponse<IProductionProcessingOrderListTablet>> {
    return this.get<ICollectionResponse<IProductionProcessingOrderListTablet>>(params, 'scheduled');
  }

  getProcessingOrdersScheduledById(id: number, params?: {}): Observable<IProductionProcessingOrderListTablet> {
    const queryParams = { scheduleId: -2, ...params };

    return this.get<IProductionProcessingOrderListTablet>(queryParams, `scheduled/${id}`);
  }

  changeRank(orderId: number, serviceId: number, params?: object) {
    return this.patch<null>(null, params, `${orderId}/service/${serviceId}/rank`);
  }

  getConsumedPerWorkCenter(orderId: number, params?: object) {
    return this.get<ICollectionResponse<IInventoryDetailsInventoryList>>(params, `${orderId}/consumedPerWorkCenter`);
  }

  updateConsumedClassify(id: number, correlationId: string, value: IEntryOperationInventoryItem, params?: {}) {
    const config = {
      headers: {
        'X-Correlation-ID': correlationId
      }
    };

    this.signalRService.setCorrelationId(correlationId);
    let request = this.factory.toInventoryItemRequest(value);

    request = { ...request, entryId: correlationId };

    return this.post<IEntryOperationInventoryItemResponse>(request, params, `${id}/consumption/classify`, config).pipe(
      map(r => this.factory.toInventoryItemResponse(r))
    );
  }

  ancillaryUpdateClassify(id: number, value: IEntryOperationInventoryItemResponse, params?: {}) {
    return this.post<IEntryOperationInventoryItemResponse>(value, params, `${id}/consumption/classify`);
  }

  updateReclassify(id: number, value: IReclassifyNcpItem, params?: {}) {
    const request = this.factory.toNcpItemRequest(value);

    return this.post<IEntryOperationInventoryItemResponse>(request, params, `${id}/consumption/reclassify`);
  }

  getAvailableReclassifyRacks(
    id: number,
    processingServiceId: number,
    data: IReclassifyNcpDetailDetails[]
  ): Observable<ICollectionResponse<IStockMaterialQuantityReference>> {
    return this.post<IReclassifyNcpDetailDetails[], ICollectionResponse<IStockMaterialQuantityReference>>(
      data,
      { processingServiceId },
      `${id}/consumption/reclassify/racks`
    );
  }

  getLotNumbers(query: object) {
    return this.get<ICollectionResponse<IProcessingOrderLotNumber>>(query, 'lotNumber');
  }

  getAvailableMaterials(params?: object): Observable<ICollectionResponse<IAvailableMaterialPerDocument>> {
    return this.get<ICollectionResponse<IAvailableMaterialPerDocument>>(params, 'availableMaterials');
  }

  createEntryOperation(id: number, value: IEntryOperationReport, params?: object): Observable<IEntryOperationReport> {
    return this.post<IEntryOperationReport>(value, params, `${id}/consumptionAndOutcome`);
  }

  createEntryOperationThreading(
    id: number,
    correlationId: string,
    value: IEntryOperationInventoryItem,
    params?: object
  ) {
    const config = {
      headers: {
        'X-Correlation-ID': correlationId
      }
    };

    this.signalRService.setCorrelationId(correlationId);
    const request = this.factory.toInventoryItemRequest(value);

    return this.post(request, params, `${id}/consumptionAndOutcome/threading`, config).pipe(
      map(r => this.factory.toInventoryItemResponse(r))
    );
  }

  getThreadingEntryOperation(productionId: number, id: number): Observable<IEntryOperationReport> {
    return this.get<IEntryOperationReport>({ productionId }, `${id}/consumptionAndOutcome/threading`);
  }

  getEntryOperation(id: number, productionId: number): Observable<IEntryOperationReport> {
    return this.get<IEntryOperationReport>({ productionId }, `${id}/consumptionAndOutcome`);
  }

  getBatchInfo(id: number, params?: object): Observable<IProcessingOrderBatchInfo> {
    return this.get<IProcessingOrderBatchInfo>(params, `${id}/batchInfo`);
  }

  uploadAttachment(file: FormData, poId: number, params: { categoryId: PoAttachmentCategory; force?: boolean }) {
    return this.post<FormData>(file, params, `${poId}/attachments`, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadAttachment(attachmentId: string, poId: number): Observable<Blob> {
    return this.get({}, `${poId}/attachments/${attachmentId}`, { responseType: 'blob' });
  }

  deleteAttachment(attachmentId: string, poId: number) {
    return this.delete({}, `${poId}/attachments/${attachmentId}`);
  }

  getAttachments(poId: number) {
    return this.get<IProcessOrderAttachment>({}, `${poId}/attachments`);
  }

  getProgress(poId: number): Observable<IProcessOrderStatus[]> {
    return this.get<IProcessOrderStatus[]>({}, `${poId}/progress`);
  }

  getTallyHeader(poId: number, poServiceId: number): Observable<IProcessingOrderPipeMeasurements[]> {
    return this.get<IProcessingOrderPipeMeasurements[]>({ header: true }, `${poId}/service/${poServiceId}/tally`);
  }

  getTallyLines(poId: number, poServiceId: number): Observable<IProcessingOrderPipeMeasurements[]> {
    return this.get<IProcessingOrderPipeMeasurements[]>({}, `${poId}/service/${poServiceId}/tally`);
  }

  getTallyLine(poId: number, poServiceId: number, params?: object): Observable<IProcessingOrderPipeMeasurements> {
    return this.get<IProcessingOrderPipeMeasurements>(
      removeEmptyAttributes(params),
      `${poId}/service/${poServiceId}/tally/line`
    );
  }

  uploadTallyFile(
    id: number,
    serviceId: number,
    data: FormData,
    params?: object
  ): Observable<IProcessingOrderTallyItem> {
    return this.post(data, removeEmptyAttributes(params), `${id}/service/${serviceId}/tally`);
  }

  updateTallyFile(
    id: number,
    serviceId: number,
    data: FormData,
    params?: object
  ): Observable<IProcessingOrderTallyItem> {
    return this.put(data, removeEmptyAttributes(params), `${id}/service/${serviceId}/tally/append`);
  }

  removeTallyFile(
    id: number,
    serviceId: number,
    body: FormData,
    params?: object
  ): Observable<IProcessingOrderTallyItem> {
    return this.delete(removeEmptyAttributes(params), `${id}/service/${serviceId}/tally`, { body });
  }

  saveTally(
    id: number,
    serviceId: number,
    body: IProcessingOrderPipeMeasurements[],
    params?: object
  ): Observable<IProcessingOrderTallyItem[]> {
    const parsedTally = this.factory.parseTallyItems(body);

    return this.put(parsedTally, removeEmptyAttributes(params), `${id}/service/${serviceId}/tally`);
  }

  getDevices(poId: number, serviceId: number, params?: object): Observable<ICollectionResponse<IVolumetricGrowth>> {
    return this.get<ICollectionResponse<IVolumetricGrowth>>(params, `${poId}/services/${serviceId}/devices`);
  }

  getDevice(poId: number, serviceId: number, deviceId: number, params?: object): Observable<IVolumetricGrowth> {
    return this.get<IVolumetricGrowth>(params, `${poId}/services/${serviceId}/devices/${deviceId}`);
  }

  updateDevice(value: IVolumetricGrowth, params?: object): Observable<IVolumetricGrowth> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/${value.id}`;

    return this.put<IVolumetricGrowth>(value, params, path);
  }

  createDevice(value: IVolumetricGrowth, params?: object): Observable<IVolumetricGrowth> {
    return this.post<IVolumetricGrowth>(value, params, `${value.orderId}/services/${value.serviceId}/devices`);
  }

  closeDevice(
    value: IVolumetricGrowth,
    body: IPatchRequest<IVolumetricGrowth>[],
    params?: object
  ): Observable<IVolumetricGrowth> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/${value.id}`;

    return this.patch(body, params, path);
  }

  getThreadInspections(
    poId: number,
    serviceId: number,
    params?: object
  ): Observable<ICollectionResponse<IRoundThread>> {
    const path = `${poId}/services/${serviceId}/devices/threadInspection`;

    return this.get<ICollectionResponse<IRoundThread>>(params, path);
  }

  getThreadInspection(poId: number, serviceId: number, deviceId: number, params?: object): Observable<IRoundThread> {
    return this.get<IRoundThread>(params, `${poId}/services/${serviceId}/devices/threadInspection/${deviceId}`);
  }

  updateThreadInspection(value: IRoundThread, params?: object): Observable<IRoundThread> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/threadInspection/${value.id}`;

    return this.put<IRoundThread>(value, params, path);
  }

  createThreadInspection(value: IRoundThread, params?: object): Observable<IRoundThread> {
    return this.post<IRoundThread>(
      value,
      params,
      `${value.orderId}/services/${value.serviceId}/devices/threadInspection`
    );
  }

  closeThreadInspection(
    value: IRoundThread,
    body: IPatchRequest<IRoundThread>[],
    params?: object
  ): Observable<IRoundThread> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/threadInspection/${value.id}`;

    return this.patch(body, params, path);
  }

  getBuckOns(poId: number, serviceId: number, params?: object): Observable<ICollectionResponse<IBuckOn>> {
    const path = `${poId}/services/${serviceId}/devices/bucker`;

    return this.get<ICollectionResponse<IBuckOn>>(params, path);
  }

  getBuckOn(poId: number, serviceId: number, deviceId: number, params?: object): Observable<IBuckOn> {
    return this.get<IBuckOn>(params, `${poId}/services/${serviceId}/devices/bucker/${deviceId}`);
  }

  updateBuckOn(value: IBuckOn, params?: object): Observable<IBuckOn> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/bucker/${value.id}`;

    return this.put<IBuckOn>(value, params, path);
  }

  createBuckOn(value: IBuckOn, params?: object): Observable<IBuckOn> {
    return this.post<IBuckOn>(value, params, `${value.orderId}/services/${value.serviceId}/devices/bucker`);
  }

  closeBuckOn(value: IBuckOn, body: IPatchRequest<IBuckOn>[], params?: object): Observable<IBuckOn> {
    const path = `${value.orderId}/services/${value.serviceId}/devices/bucker/${value.id}`;

    return this.patch(body, params, path);
  }

  getTolerance(value: IVolumetricGrowth, params?: object): Observable<IVolumetricGrowthTolerance> {
    return this.get(
      params,
      `${value.orderId}/services/${value.serviceId}/devices/${value.id}/measurements/volumetricGrowth/tolerances`
    );
  }

  createVolumetricGrowthMeasurements(
    value: IVolumetricGrowthMeasurements,
    options: IVolumetricGrowth,
    params?: object
  ): Observable<IVolumetricGrowthMeasurements> {
    return this.post(
      value,
      params,
      `${options.orderId}/services/${options.serviceId}/devices/${options.id}/measurements/volumetricGrowth`
    );
  }

  updateVolumetricGrowthMeasurements(
    value: IVolumetricGrowthMeasurements,
    options: IVolumetricGrowth,
    params?: object
  ): Observable<IVolumetricGrowthMeasurements> {
    return this.put(
      value,
      params,
      `${options.orderId}/services/${options.serviceId}/devices/${
        options.id
      }/measurements/volumetricGrowth/${encodeURIComponent(value?.heatNumber)}`
    );
  }

  getVolumetricGrowthMeasurementsList(
    poId: number,
    serviceId: number,
    deviceId: number,
    params?: object
  ): Observable<ICollectionResponse<IVolumetricGrowthItemList>> {
    return this.get(params, `${poId}/services/${serviceId}/devices/${deviceId}/measurements/volumetricGrowth`);
  }

  getVolumetricGrowthMeasurement(
    params: {
      poId: number;
      serviceId: number;
      deviceId: number;
      heatNumber: string;
    },
    query?: object
  ): Observable<IVolumetricGrowthMeasurements> {
    return this.get(
      query,
      `${params.poId}/services/${params.serviceId}/devices/${
        params.deviceId
      }/measurements/volumetricGrowth/${encodeURIComponent(params.heatNumber)}`
    );
  }

  getServiceProgress(
    params: { poId: number; serviceId: number; parentEventId: number },
    query?: object
  ): Observable<IProcessingOrderProgress> {
    return this.get<IProcessingOrderProgress>(
      query,
      `${params.poId}/service/${params.serviceId}/planningEvent/${params.parentEventId}/progress`
    );
  }

  getSawCutList(poId: number, serviceId: number, params?: object): Observable<IAncillarySawCutList[]> {
    return this.get<IAncillarySawCutList[]>(params, `${poId}/service/${serviceId}/sawCutSummary`);
  }

  getGagList(poId: number, serviceId: number, params?: object): Observable<IAncillaryGagList[]> {
    return this.get<IAncillaryGagList[]>(params, `${poId}/service/${serviceId}/gagSummary`);
  }

  getAncillaryItemById(
    params: {
      poId: number;
      serviceId: number;
      ancillaryId: number;
    },
    query?: object
  ): Observable<IAncillaryItemResponse> {
    return this.get<IAncillaryItemResponse>(
      query,
      `${params.poId}/service/${params.serviceId}/classificationEvent/${params.ancillaryId}`
    );
  }

  updateProcessingOrderService(
    value: IProductionProcessingOrderListTablet,
    body: IPatchRequest<IProductionProcessingOrderListTablet>[],
    params?: object
  ): Observable<IProductionProcessingOrderListTablet> {
    const path = `${value.processingOrderId}/service/${value.processingServiceId}`;

    return this.patch(body, params, path);
  }

  updateScheduledEvent(
    id: number,
    data: IPatchRequest<IPlanningItem>[],
    params?: object
  ): Observable<IPatchRequest<IPlanningItem>> {
    const confirmedStatus = -2;

    return this.patch(data, params, `schedule/${confirmedStatus}/planningEvents/${id}`);
  }

  getDefaultServiceProperties(serviceTypeId: number, params?: object): Observable<{ [key: string]: ISelectOption }> {
    return this.get<{ [key: string]: ISelectOption }>(params, `/service/serviceTypes/${serviceTypeId}/defaults`);
  }

  getOperationResult(proId: number, lineId: number) {
    return this.get<IOperationDetail[]>({}, `${proId}/service/${lineId}/operationResult`);
  }

  createOperationResult(proId: number, lineId: number, data: IOperationReport[]) {
    return this.post<IOperationReport[]>(data, {}, `${proId}/service/${lineId}/operationResult`);
  }
}
