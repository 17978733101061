<div
  *ngIf="notificationGroups.length === 0"
  class="empty-list text-center margin-top-8"
  i18n="@@notifications.list.empty"
>
  You have no notifications at the moment. Please try again later or change your filtering criteria
</div>
<div class="col-12">
  <erp-expansion-panel *ngFor="let group of notificationGroups" class="margin-top-4" expandDirection="down">
    <erp-expansion-panel-header>
      <erp-label class="title" color="primary">
        {{ group.title }}
      </erp-label>
    </erp-expansion-panel-header>
    <erp-expansion-panel-body>
      <div class="flex align-items-center notification-card margin-bottom-1" *ngFor="let item of group.items">
        <span class="notification-type" [ngClass]="notificationTypes[item.typeId]"></span>
        <div class="grid align-items-center stretch-horizontally" [ngClass]="isTablet ? 'margin-2' : 'margin-4'">
          <div [ngClass]="isTablet ? 'col-2' : 'col-1'">
            <erp-button
              *ngIf="item.referenceItemNumber"
              variant="link"
              color="primary"
              (click)="onClickDocumentId(item)"
            >
              <span class="document-link">{{ item.referenceItemNumber }}</span>
            </erp-button>
          </div>
          <div [ngClass]="isTablet ? 'col-4' : 'col-8'" [class.unread]="!item.isRead">
            {{ item.title }}
          </div>
          <div [ngClass]="isTablet ? 'col-3' : 'col-1'" class="user-name">
            {{ item.fromUserName }}
          </div>
          <div class="col-1 flex justify-content-flex-end">
            <erp-button variant="icon" color="primary" (click)="switchReadState(item)">
              <erp-icon
                [icon]="item.isRead ? 'action:mail-open' : 'action:mail-closed'"
                class="read-icon"
                size="fit"
              ></erp-icon>
            </erp-button>
          </div>
          <div [ngClass]="isTablet ? 'col-2' : 'col-1'" class="text-end">
            {{ item.date | erpDateOrTime }}
          </div>
        </div>
      </div>
    </erp-expansion-panel-body>
  </erp-expansion-panel>
</div>
<ng-template>
  <ng-container i18n="@@notifications.list.group.today.title">Today</ng-container>
  <ng-container i18n="@@notifications.list.group.yesterday.title">Yesterday</ng-container>
  <ng-container i18n="@@notifications.list.group.older.title">Older</ng-container>

  <ng-container i18n="@@notifications.toast.created.title">Item Created</ng-container>
  <ng-container i18n="@@notifications.toast.updated.title">Item Updated</ng-container>
  <ng-container i18n="@@notifications.toast.assignment.title">New Assignment</ng-container>
  <ng-container i18n="@@notifications.toast.deleted.title">Item Deleted</ng-container>
</ng-template>
