import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export function Cache(expirationInSeconds?: number) {
  const cache = new Map();

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const key = JSON.stringify(args);
      if (cache.has(key)) {
        return cache.get(key);
      }

      const result = originalMethod.apply(this, args);

      if (result instanceof Observable) {
        const sharedResult = result.pipe(shareReplay(1));
        cache.set(key, sharedResult);
        if (expirationInSeconds) {
          setTimeout(() => cache.delete(key), expirationInSeconds * 1000);
        }
        return sharedResult;
      } else if (result instanceof Promise) {
        result.then(data => {
          cache.set(key, Promise.resolve(data));
          if (expirationInSeconds) {
            setTimeout(() => cache.delete(key), expirationInSeconds * 1000);
          }
        });
        return result;
      } else {
        cache.set(key, result);
        if (expirationInSeconds) {
          setTimeout(() => cache.delete(key), expirationInSeconds * 1000);
        }
        return result;
      }
    };

    return descriptor;
  };
}
