import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ERPFocusDirective,
  ERPHighlightDirective,
  ERPMoveFocusDirective,
  ERPResizeTableDirective,
  ERPScrollNearEndDirective
} from './directives';
import {
  ERPAbbreviationPipe,
  ERPAmountPipe,
  ERPDateOrTimePipe,
  ERPDatePipe,
  ERPDateTimePipe,
  ERPFindPropPipe,
  ERPProgressPipe,
  ERPTimeFromNumberPipe,
  ERPTimePipe,
  ERPTruncatePipe
} from './pipes';
import { ERPWidgetModule } from './widget';

@NgModule({
  declarations: [
    ERPDatePipe,
    ERPTruncatePipe,
    ERPDateTimePipe,
    ERPDateOrTimePipe,
    ERPTimePipe,
    ERPFindPropPipe,
    ERPAmountPipe,
    ERPAbbreviationPipe,
    ERPMoveFocusDirective,
    ERPTimeFromNumberPipe,
    ERPProgressPipe,
    ERPResizeTableDirective,
    ERPScrollNearEndDirective,
    ERPFocusDirective,
    ERPHighlightDirective
  ],
  imports: [CommonModule],
  exports: [
    CommonModule,
    ERPWidgetModule,
    ERPDatePipe,
    ERPTruncatePipe,
    ERPDateTimePipe,
    ERPDateOrTimePipe,
    ERPTimePipe,
    ERPFindPropPipe,
    ERPAmountPipe,
    ERPAbbreviationPipe,
    ERPTimeFromNumberPipe,
    ERPProgressPipe,
    ERPResizeTableDirective,
    ERPScrollNearEndDirective,
    ERPMoveFocusDirective,
    ERPFocusDirective,
    ERPHighlightDirective
  ]
})
export class ERPSharedModule {}
