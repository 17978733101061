import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  CustomValidators,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ISelectOption } from '../select';

const MAX_SHORT_FIELD = 20;
const MAX_LONG_FIELD = 250;

@Component({
  selector: 'erp-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPAddressComponent<T> extends BaseFormComponent<T> implements OnInit {
  @Input() readonly mode: 'view' | 'edit' | 'create';
  @Input() readonly withPhone: boolean = true;
  @Input() readonly withEmail: boolean = true;
  @Input() readonly isCountryRequired: boolean = true;
  @Input() readonly countries: ISelectOption[];
  readonly DEFAULT_COUNTRY_ID = 187; // USA

  readonly form = new UntypedFormGroup({
    addressLine1: new UntypedFormControl(null, [Validators.maxLength(MAX_LONG_FIELD)]),
    addressLine2: new UntypedFormControl(null, [Validators.maxLength(MAX_LONG_FIELD)]),
    city: new UntypedFormControl(null, [Validators.maxLength(MAX_LONG_FIELD)]),
    region: new UntypedFormControl(null, [Validators.maxLength(MAX_LONG_FIELD)]),
    zip: new UntypedFormControl(null, [Validators.maxLength(MAX_SHORT_FIELD)])
  });

  ngOnInit() {
    if (this.withPhone) {
      this.form.addControl('phoneNumber', new UntypedFormControl(null, [Validators.maxLength(MAX_SHORT_FIELD)]));
    }

    if (this.withEmail) {
      this.form.addControl(
        'email',
        new UntypedFormControl(null, [Validators.email, Validators.maxLength(MAX_LONG_FIELD)])
      );
    }

    if (this.isCountryRequired) {
      this.form.addControl('countryId', new UntypedFormControl(this.DEFAULT_COUNTRY_ID, [Validators.required]));
    } else {
      this.form.addControl('countryId', new UntypedFormControl(null));
    }
  }

  getCountryName(id: number) {
    return this.countries.find(x => x.id === id)?.value;
  }
}
