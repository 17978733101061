<div *ngIf="selectedAttachment$ | async as selectedAttachment" class="attachment-viewer-header">
  <h3>{{ selectedAttachment?.fileName }}</h3>
  <div *ngIf="!attachmentPreviewError$?.value" class="page">
    <erp-label>Page:</erp-label>
    <erp-number
      [(ngModel)]="page"
      [ngModelOptions]="{ standalone: true }"
      type="integer"
      label="Page"
      class="input no-bottom-padding"
    >
    </erp-number>
  </div>

  <erp-button *ngIf="!attachmentPreviewError$?.value" color="white" variant="link" (click)="onOpenPDFInNewTab()">
    Open PDF in new tab
    <erp-icon icon="action:open"></erp-icon>
  </erp-button>

  <erp-button *ngIf="!attachmentPreviewError$?.value" color="white" variant="link" (click)="onPrintPDFAttachment()">
    Print
    <erp-icon icon="action:print-file"></erp-icon>
  </erp-button>

  <div *ngIf="!attachmentPreviewError$?.value" class="page">
    <erp-label>Zoom:</erp-label>
    <erp-button color="white" variant="link" (click)="onPlusZoom()">
      <erp-icon icon="action:add"></erp-icon>
    </erp-button>

    <erp-button color="white" variant="link" (click)="onMinusZoom()">
      <erp-icon icon="action:remove"></erp-icon>
    </erp-button>
  </div>

  <erp-button color="white" variant="link" (click)="onDownloadAttachment()">
    <erp-icon icon="action:download"></erp-icon>
    Download
  </erp-button>
</div>

<mat-card *ngIf="attachmentPreviewError$?.value" class="attachment-error col-12">
  <h3>{{ attachmentPreviewError$.value }}</h3>
</mat-card>

<pdf-viewer
  *ngIf="selectedAttachment$ | async"
  [src]="src"
  [(page)]="page"
  [rotation]="0"
  [original-size]="true"
  [show-all]="true"
  [fit-to-page]="true"
  [zoom]="zoom"
  [zoom-scale]="'page-width'"
  [stick-to-page]="false"
  [render-text]="true"
  [external-link-target]="'blank'"
  [render-text-mode]="1"
  [autoresize]="true"
  [show-borders]="true"
  style="width: 100%; height: calc(100vh - 15rem)"
  (error)="onError($event)"
  (after-load-complete)="onAfterLoadComplete($event)"
></pdf-viewer>
